import React from "react"
import {
  Box,
  PageActions,
  PageBody,
  PageHeader,
  PageTitle,
  PageToolbar,
  StackView,
} from "@planningcenter/tapestry-react"
import PeopleThemeProvider from "components/people_theme_provider"

export const sidebarNakedBtnProps = {
  as: "button",
  theme: "sidebarFilter",
  variant: "nakedNoHover",
  fontWeight: 400,
  fontSize: 13,
  paddingTop: 0.5,
  paddingBottom: 0.5,
  hover: {
    textDecoration: "underline",
  },
}

export const badgeProps = {
  as: "button",
  marginVertical: 0.25,
  size: "xs",
  color: {
    background: "primary-lightest",
    foreground: "base2",
  },
  radius: "pill",
  cursor: "pointer",
  hover: {
    backgroundColor: "#e0e8fa",
  },
  active: {
    backgroundColor: "#e0e8fa",
  },
  focus: {
    backgroundColor: "#e0e8fa",
    outline: "revert",
  },
}

/**
 * Page layout component containing a header, left sidebar, and main body
 *
 * @param {Object} config
 * @param {?string} config.title - page title rendered in header
 * @param {?*} config.actions - page-level actions rendered in header; can be any renderable React node(s)
 * @param {?*} config.sidebar - any renderable React nodes to display in sidebar
 * @param {?*} config.body - any rendereable React nodes to display in page body
 */
export default function Page({
  title = null,
  actions = null,
  sidebar = null,
  body = null,
}) {
  return (
    <PeopleThemeProvider>
      <Box width="100%">
        <PageHeader
          toolbar={
            <PageToolbar>
              <PageTitle
                visible={false}
                mediaQueries={{ lg: { visible: true } }}
              >
                {title}
              </PageTitle>
              <PageActions
                width="100%"
                mediaQueries={{ lg: { width: "inherit" } }}
              >
                {actions}
              </PageActions>
            </PageToolbar>
          }
        />
        <PageBody
          axis="vertical"
          grow={1}
          shrink={1}
          spacing={1}
          marginHorizontal="auto"
          mediaQueries={{
            lg: {
              axis: "horizontal",
              distribution: "center",
              spacing: 2,
            },
          }}
        >
          <StackView spacing={2} mediaQueries={{ lg: { basis: "340px" } }}>
            {sidebar}
          </StackView>
          <StackView spacing={2} flex={1} maxWidth={"844px"}>
            {body}
          </StackView>
        </PageBody>
      </Box>
    </PeopleThemeProvider>
  )
}
