import React, { useEffect } from "react"
import pcoURL from "@planningcenter/url"
import api from "utils/api"
import { formatNumberLocale } from "utils/number_formatting"
import useHideStaffOnlyPinkBoxes from "hooks/use_hide_staff_only_pink_boxes"

// ui components
import {
  Badge,
  Box,
  Button,
  Card,
  Divider,
  Heading,
  Icon,
  Link,
  List,
  PageButton,
  StackView,
  Text,
} from "@planningcenter/tapestry-react"
import Page, {
  badgeProps,
  sidebarNakedBtnProps,
} from "components/layouts/page_with_sidebar"
import SvgForms from "components/svgs/blank_forms"
import LoadingView from "components/loading_view"
import Alert from "utils/alert"

// hooks
import useForms from "hooks/forms/use_forms"
import useFilterByCollectionItem from "hooks/use_filter_by_collection_item"
import useSidebarFilters from "hooks/use_sidebar_filters"
import useFilterBySearchPhrase from "hooks/use_filter_by_search_phrase"

export const NEW_FORM_PLACEHOLDER_NAME = "Untitled Form"
export const OTHER_OPTION_ID = -1
export const FormFieldContext = React.createContext()

const handleNewFormClick = () => {
  if (window.currentOrganization.subdomain) {
    const attributes = { name: NEW_FORM_PLACEHOLDER_NAME, active: true }

    api
      .post("/people/v2/forms", { data: { attributes } })
      .then(({ data }) => {
        Turbolinks.visit(`/forms/${data.id}/fields`)
      })
      .catch(() => {
        Alert.error({
          title: "Uh oh!",
          text: "There was a problem creating a new form. Please try again.",
        })
      })
  } else {
    const url = `${pcoURL(railsEnv)("accounts")}/organization/church_center`

    Alert.warn({
      title: "Subdomain not set",
      html: `Before you start using Forms, your church will need to choose a subdomain for the public-facing side of Forms <a href="${url}">here</a>.`,
    })
  }
}

export default function Forms({ campuses = [], categories = [] }) {
  useEffect(() => window.scrollTo(0, 0), [selectedCampusId, selectedCategoryId])
  useHideStaffOnlyPinkBoxes()

  const orgHasMultipleCampuses = campuses.length > 1

  const {
    Filter: CampusFilter,
    filterProps: campusFilterProps,
    selectedItemId: selectedCampusId,
    resetFilter: resetCampusFilter,
    setSelectedItem: setSelectedCampus,
  } = useFilterByCollectionItem({
    collection: campuses,
    localStorageKey: "formCampusId",
    message: "All campuses",
  })

  const {
    SearchFilterInput,
    searchFilterInputProps,
    searchPhrase,
    resetSearchFilter,
  } = useFilterBySearchPhrase({
    placeholder: "Find a form",
  })

  const {
    Filter: CategoryFilter,
    filterProps: categoryFilterProps,
    selectedItemId: selectedCategoryId,
    resetFilter: resetCategoryFilter,
    setSelectedItem: setSelectedCategory,
  } = useFilterByCollectionItem({
    collection: categories,
    localStorageKey: "formCategoryId",
    message: "All categories",
    manageLink: ["/forms/categories", "Manage categories"],
  })

  const {
    SidebarFilterButtonGroup,
    sidebarFilterButtonGroupProps,
    selectedFilter,
    resetFilter,
  } = useSidebarFilters({
    localStorageKey: "formFilter",
    initialValue: "open",
    filters: [
      {
        filterValue: "recently_viewed",
        icon: "general.clock",
        title: "Recently Viewed",
        count: () => formatNumberLocale(totalRecentlyViewedCount, "-"),
      },
      {
        filterValue: "open",
        icon: "general.threeHorizontalListItems",
        title: "Open",
        count: () => formatNumberLocale(totalOpenCount, "-"),
      },
      {
        filterValue: "closed",
        icon: "general.hideEye",
        title: "Closed",
        count: () => formatNumberLocale(totalClosedCount, "-"),
      },
      {
        filterValue: "archived",
        icon: "general.tray",
        title: "Archived",
        count: () => formatNumberLocale(totalArchivedCount, "-"),
      },
    ],
  })

  const {
    forms,
    currentPersonCanManageAnyDeletedForms,
    Pagination,
    paginationProps,
    totalFilteredCount,
    totalRecentlyViewedCount,
    totalOpenCount,
    totalClosedCount,
    totalArchivedCount,
    status,
  } = useForms({
    campusId: selectedCampusId,
    categoryId: selectedCategoryId,
    filter: searchPhrase ? null : selectedFilter,
    searchPhrase,
  })

  const showRecentlyDeletedLink =
    status !== "loading" &&
    (window.currentPerson.impersonating ||
      currentPersonCanManageAnyDeletedForms)
  const showSpamSubmissionsLink =
    window.features.ROLLOUT_spam_form_submissions &&
    status !== "loading" &&
    (window.currentPerson.impersonating || window.currentPerson.manager)

  function resetAllFilters() {
    resetCampusFilter()
    resetCategoryFilter()
    resetFilter()
    resetSearchFilter()
  }

  const actions = window.currentPerson.permissions.canCreateForms ? (
    <PageButton
      variant="primary"
      title="New form"
      onClick={handleNewFormClick}
      data-pendo="create_form"
    />
  ) : null

  const sidebar = (
    <>
      <Card padding={2} spacing={2}>
        <SearchFilterInput {...searchFilterInputProps} />
        <StackView spacing={1}>
          {orgHasMultipleCampuses && <CampusFilter {...campusFilterProps} />}
          <CategoryFilter {...categoryFilterProps} />
        </StackView>
        {searchPhrase.length > 0 ? (
          <StackView
            axis="horizontal"
            spacing={1}
            alignment="center"
            width="100%"
            color="grey-7"
            paddingLeft="11px"
            paddingRight="8px"
          >
            <Icon name="general.search" />
            <Text as="em" flex={1}>
              Forms matching ‘{searchPhrase}’
            </Text>
            <Badge size="xs" radius="pill" title={totalFilteredCount} />
            <Button
              icon="general.x"
              onClick={resetSearchFilter}
              tooltip="Clear Search"
              variant="naked"
              size="sm"
            />
          </StackView>
        ) : (
          <SidebarFilterButtonGroup {...sidebarFilterButtonGroupProps} />
        )}
      </Card>
      <StackView axis="vertical" paddingHorizontal={2}>
        {showRecentlyDeletedLink && (
          <Button
            {...sidebarNakedBtnProps}
            className={
              currentPersonCanManageAnyDeletedForms ? "" : "staff-only"
            }
            iconLeft={{
              name: "general.trash",
              size: "12px",
            }}
            data-modal-url="/forms/deleted"
          >
            Recently deleted forms
          </Button>
        )}
        {showSpamSubmissionsLink && (
          <Button
            {...sidebarNakedBtnProps}
            iconLeft={{
              name: "general.outlinedSlashCircle",
              size: "12px",
            }}
            onClick={() => {
              Turbolinks.visit("/spam_form_submissions")
            }}
          >
            Spam submissions
          </Button>
        )}
      </StackView>
    </>
  )

  let body
  if (status === "loading") {
    body = <LoadingView label="Loading forms..." />
  } else if (status === "blank_state") {
    body = <BlankState />
  } else if (status === "no_matching_forms") {
    body = <NoMatchingForms resetAllFilters={resetAllFilters} />
  } else if (status === "forms_list") {
    body = (
      <FormList
        forms={forms}
        campusFilterEnabled={campuses.length > 1}
        setSelectedCampus={setSelectedCampus}
        setSelectedCategory={setSelectedCategory}
        pagination={<Pagination {...paginationProps} />}
      />
    )
  }

  return <Page title="Forms" actions={actions} sidebar={sidebar} body={body} />
}

const FormList = ({
  campusFilterEnabled,
  forms,
  setSelectedCampus,
  setSelectedCategory,
  pagination,
}) => (
  <>
    <List as="ul" spacing={2} divider={false} role="list">
      {forms.map(form => (
        <List.Item as="li" key={form.id} padding={0} role="list-item">
          <Card width="100%" padding={2} spacing={2}>
            <Box>
              <StackView
                axis="horizontal"
                spacing={1}
                alignItems="flex-start"
                css={{ float: "right" }}
              >
                {form.campus && campusFilterEnabled ? (
                  <Badge
                    {...badgeProps}
                    title={form.campus.attributes.name}
                    renderLeft={<Icon name="general.outlinedLocationPin" />}
                    onClick={() => {
                      if (form.campus.id) {
                        setSelectedCampus(form.campus)
                      }
                    }}
                  />
                ) : null}
                {form.category ? (
                  <Badge
                    {...badgeProps}
                    title={form.category.attributes.name}
                    onClick={() => setSelectedCategory(form.category)}
                  />
                ) : null}
              </StackView>
              <Heading
                level={3}
                color="primary"
                truncate
                maxWidth="100%"
                css={{ float: "left" }}
              >
                <Link underline={false} to={`/forms/${form.id}`}>
                  {form.attributes.name}
                </Link>
              </Heading>
            </Box>
            <Divider />
            <StackView axis="horizontal" spacing={2} color="grey-8">
              <Box css={{ flex: 1 }}>
                {`${form.attributes.submission_count?.toLocaleString()} ${
                  form.attributes.submission_count === 1
                    ? "Submission"
                    : "Submissions"
                }`}
              </Box>
              <Box css={{ float: "right" }}>
                {form.attributes.active && !form.attributes.archived ? (
                  <Button
                    variant="outline"
                    to={form.attributes.public_url}
                    external
                    tooltip={{ title: "View form" }}
                    icon="general.newWindow"
                    aria-label="Open public form in new window"
                  />
                ) : (
                  <>
                    <Badge
                      {...badgeProps}
                      as={Link}
                      aria-label="Settings for this form"
                      to={`/forms/${form.id}/settings`}
                      color={{
                        foreground: "warning",
                        background: "transparent",
                      }}
                      hover={{
                        color: "warning",
                      }}
                      active={{
                        color: "warning",
                      }}
                      stroke="warning"
                      strokeAlign="inside"
                      strokeWeight={1}
                      underline={false}
                      title={`${
                        form.attributes.archived ? "Archived" : "Closed"
                      }`}
                      renderLeft={
                        <Icon
                          name={
                            form.attributes.archived
                              ? "general.tray"
                              : "general.hideEye"
                          }
                        />
                      }
                    />
                  </>
                )}
              </Box>
            </StackView>
          </Card>
        </List.Item>
      ))}
    </List>
    {pagination}
  </>
)

const NoMatchingForms = ({ resetAllFilters }) => (
  <BlankStateWrapper>
    <h2>No forms here</h2>
    <p>Try adjusting your filters.</p>
    <div className="btn-controls">
      <Button
        variant="fill"
        theme="primary"
        onClick={resetAllFilters}
        title="Clear filters"
      />
    </div>
  </BlankStateWrapper>
)

const BlankState = () => (
  <BlankStateWrapper>
    <h2>Forms</h2>
    <p>
      Create custom forms to easily and securely collect information from the
      people in your church.
    </p>
    <p>
      Forms will show here after you create them.{" "}
      <a
        href="https://pcopeople.zendesk.com/hc/en-us/articles/8356716619291-Forms"
        className="secondary-link"
      >
        What are forms?
      </a>
    </p>
    <div className="d-f jc-c jc-fs@lg">
      <div className="btn-controls">
        <button
          className="btn btn--primary m-0!"
          onClick={handleNewFormClick}
          data-pendo="create_form"
        >
          New form
        </button>
      </div>
    </div>
  </BlankStateWrapper>
)

const BlankStateWrapper = ({ children }) => (
  <div className="list-container--index-cards m-0!">
    <div className="blank-state blank-state--horz">
      <div className="img-horz-placeholder">
        <SvgForms />
      </div>
      <div className="content blank-state__msg blank-state__msg--wide">
        {children}
      </div>
    </div>
  </div>
)
